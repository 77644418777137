<template>
  <v-list class="transparent">
    <v-list-item class="px-1 mx-0">
      <v-list-item-avatar class="mx-0" tile size="30">
        <v-img :src="siteLogo()" width="40px" contain />
      </v-list-item-avatar>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "KTBrand",
  mounted() {},
  methods: {
    siteLogo() {
      if (this.$root.mobile) {
        return "/media/logos/logo.png";
      } else return "/media/logos/logo-white.png";
    }
  }
};
</script>
